// @ts-nocheck
import Vue from 'vue'
import VueRouter from 'vue-router'
import { verifyLogin, verifyGuest } from './middleware'

import adminRoutes from './admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/admin/Index'),
    beforeEnter: verifyLogin,
    children: adminRoutes,
  },
  {
    path: '/guest',
    component: () => import('@/views/guest/Index'),
    beforeEnter: verifyGuest,
    children: require('./guest.route'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
