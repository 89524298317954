Array.prototype.last = function () {
  return this[this.length - 1]
}
Array.prototype.first = function () {
  return this[0]
}
Array.prototype.objectInsertion = function (object, localKey, foreignKey) {
  const arr = this.valueOf()
  if (!foreignKey || !localKey || !Object.keys(object).length) return arr
  const current = object[foreignKey]
  let i = arr.length - 1
  while (i > -1 && current < arr[i][localKey]) {
    arr[i + 1] = arr[i]
    i -= 1
  }
  arr[i + 1] = object
  return arr
}

Array.prototype.or = function () {
  return this.valueOf().some(condition => condition)
}

Array.prototype.and = function () {
  return this.valueOf().every(condition => condition)
}

Array.prototype.sum = function (args) {
  const array = this.valueOf()
  const sum = (total, item) => {
    if (args instanceof Function) return total + args(item)
    return total + item[args]
  }
  return array.reduce(sum, 0)
}