import store from '@/store/index'
import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import VueCookies from 'vue-cookies'
import jwtDecode from 'jwt-decode'

Vue.use(VueCryptojs)
Vue.use(VueCookies)

const decodeExpiry = token => {
  if (!token) return
  const { exp } = jwtDecode(token)
  return new Date(new Date(0).setUTCSeconds(exp))
}

// const decryptData = data => Vue.CryptoJS.AES.decrypt(data, process.env.VUE_APP_COOKIE_SECRET).toString(Vue.CryptoJS.enc.Utf8)

// const decryptCookie = key => {
//   const cookie = Vue.$cookies.get(key) || null
//   if (!cookie) return
//   return decryptData(cookie)
// }
// const cookieToken = decryptCookie('token') || null

const sessionValid = () => {
  const exp = store.getters['auth/exp'] || null
  if (!exp) return false
  return new Date(exp) >= new Date()
}

const verifyLogin = (to, from, next) => {
  if (!sessionValid()) {
    store.dispatch('auth/clearToken')
    next({ name: 'login' })
    return
  }
  next()
}

const homePage = next => {
  const path = store.state.auth.mainPage
  if (!path) {
    next('/')
    return
  }
  next(path)
}

const verifyGuest = (to, from, next) => {
  if (!sessionValid()) {
    next()
    return
  }
  homePage(next)
}

const restrictedAccess = (allowedAccess, roles = []) => (to, from, next) => {
  const { accessLevel } = store.state.auth
  if (accessLevel < allowedAccess) {
    homePage(next)
    return
  }
  next()
}

export {
  verifyLogin,
  verifyGuest,
  restrictedAccess,
}
