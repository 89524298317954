import { restrictedAccess } from '../middleware'

export default {
  path: 'suppliers',
  component: () => import('@/views/admin/suppliers/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'suppliers.table',
      component: () => import('@/views/admin/suppliers/Table'),
    },
    {
      path: 'create',
      name: 'suppliers.create',
      component: () => import('@/views/admin/suppliers/Create'),
    },
    {
      path: 'categories',
      name: 'suppliers.categories',
      component: () => import('@/views/admin/suppliers/categories/Index'),
    },
  ],
}
