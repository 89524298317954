<template lang="pug">
v-tabs(
  :background-color="backgroundColor"
  :center-active="centerActive"
  :dark="dark"
  show-arrows
  :slider-color="sliderColor"
)
  v-tab(
    v-for="(item, index) in items"
    :key="index"
    dark
    link
    exact
    v-if="!item.hidden"
    :to="item.path"
  )
    slot(
      name="tab.item"
      v-bind:item="item"
    )
      span {{ item.label }}
</template>
<script>
export default {
  name: 'BaseTabs.vue',
  props: {
    backgroundColor: {
      type: String,
      default: '',
    },
    sliderColor: {
      type: String,
      default: '',
    },
    centerActive: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
/**
 * @typedef {Object} BaseNavigationTabItem
 * @property {string} label
 * @property {import('vue-router').RawLocation} path
 */
</script>
