<template lang="pug">
  v-card
    v-card-title(v-html="title")
    v-card-text(v-html="text")
</template>
<script>
export default {
  name: 'BaseCard',
  props: ['title', 'text'],
}
</script>