import { restrictedAccess } from '../middleware'

export default {
  path: 'customers',
  component: () => import('@/views/admin/customers/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'customers.table',
      component: () => import('@/views/admin/customers/Table'),
    },
    {
      path: 'create',
      name: 'customers.create',
      component: () => import('@/views/admin/customers/Create'),
    },
    {
      path: ':customer/edit',
      name: 'customers.edit',
      component: () => import('@/views/admin/customers/Edit'),
    },
    {
      path: 'applications',
      name: 'customers.applications',
      component: () => import('@/views/admin/customers/applications/Index'),
    },
    {
      path: ':customerId/forecasts',
      component: () => import('@/views/admin/customers/forecasts/Index'),
      children: [
        {
          path: '',
          name: 'customers.forecasts.table',
          component: () => import('@/views/admin/customers/forecasts/Table'),
        },
        {
          path: 'sow-pigs',
          name: 'customers.forecasts.sow-pigs',
          component: () => import('@/views/admin/customers/forecasts/sow-pigs/Index'),
        },
        {
          path: 'create',
          name: 'customers.forecasts.create',
          component: () => import('@/views/admin/customers/forecasts/Create'),
        },
        {
          path: ':customerForecastId/edit',
          name: 'customers.forecasts.edit',
          component: () => import('@/views/admin/customers/forecasts/Create'),
        },
      ],
    },
    {
      path: ':customer/detail/create',
      name: 'customers.detail.create',
      component: () => import('@/views/admin/customers/details/Create'),
    },
    {
      path: ':customer/detail/edit',
      name: 'customers.detail.edit',
      component: () => import('@/views/admin/customers/details/Edit'),
    },
    {
      path: 'product/categories',
      name: 'products.categories',
      component: () => import('@/views/admin/product-categories/Index'),
    },
    {
      path: 'categories',
      name: 'customers.categories',
      component: () => import('@/views/admin/customers/categories/Index'),
    },
  ],
}
