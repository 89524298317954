<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script>
// import { mapState } from 'vuex'
import globalEvents from '@/components/mixins/GlobalEvents'

export default {
  name: 'App',
  mixins: [globalEvents],
}
</script>
<style lang="scss">
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
     /* remove scrollbar space */
    background-color: var(--v-primary-base);
    border-radius: 10px;
    /* optional: just make scrollbar invisible */
  }
  .theme--dark.v-application {
    background-color: var(--v-background-base, #121212) !important;
  }
  .theme--light.v-application {
    background-color: var(--v-background-base, white) !important;
  }
</style>
