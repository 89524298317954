import '@/assets/app.css'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueCryptojs from 'vue-cryptojs'
import excel from 'vue-excel-export'
import VuetifyConfirm from 'vuetify-confirm'
import SweetAlertVuetify from 'sweet-alert-vuetify'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/prototypes'
import '@/components/global'
import '@/assets/sass/global.sass'

Vue.use(SweetAlertVuetify, vuetify)
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Accept',
  buttonFalseText: 'Discard',
  color: 'warning',
  icon: 'mdi-alert-outlined',
  title: 'CONFIRM',
  width: 350,
  property: '$confirm',
})

Vue.use(excel)
Vue.use(VueCookies)
Vue.use(VueCryptojs)

const IS_PRODUCTION = process.env.NODE_ENV === 'production'

Vue.config.productionTip = IS_PRODUCTION
Vue.config.silent = !IS_PRODUCTION
Vue.config.devtools = !IS_PRODUCTION

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
