<template lang="pug">
  div.text-center
    v-menu(
      transition="slide-x-transition"
    )
      template(v-slot:activator="{ on }")
        v-btn(
          v-bind="$attrs"
          v-on="on"
        )
          slot(
            name="default"
          )
      v-list
        v-list-item-group(
          v-model="selectedItem"
          :color="selectItemColor"
        )
          v-list-item(
            v-for="(item, index) in items"
            :key="index"
            :value="item"
          )
            v-list-item-title
              slot(
                name="item"
                :slot-scope="item"
              )
                span {{ !itemText ? item : item[itemText] }}
</template>
<script>
export default {
  name: 'BaseDropdown',
  props: {
    value: {
      type: Object,
      default: () => ({ }),
    },
    items: {
      type: Object,
      default: () => ({ }),
    },
    itemText: {
      type: String,
      default: '',
    },
    selectItemColor: {
      type: String,
      default: 'primary',
    },
  },
  data () {
    return {
      selectedItem: {},
    }
  },
  watch: {
    value (value) {
      this.selectedItem = value
    },
    selectedItem (item) {
      const { objectCompare } = Window
      if (objectCompare(this.value, item)) return
      this.$emit('input', item)
    },
  },
}
</script>