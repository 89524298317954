<template lang="pug">
  v-menu(
    v-bind="$attrs"
    v-model="menu"
  )
    template(v-slot:activator="{ on }")
      slot(name="trigger" v-bind="{ on, attrs }")
        v-btn(
          text
          x-small
          v-on="on"
          v-bind="attrs"
        )
          span Open
    v-card
      v-toolbar(
        dense
        flat
        dark
        v-bind="$attrs"
      )
        slot(name="header")
          v-icon {{ icon }}
          span {{ title }}
      v-container
        slot(name="content")
      v-divider
      v-card-actions
        slot(name="actions")
          v-btn(
            color="red"
            small
            text
            dense
            @click="menu = false"
          )
            span Close
</template>
<script>
export default {
  name: 'SearchComponent',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: String,
    title: String,
  },
  data () {
    return {
      menu: false,
    }
  },
  computed: {
    attrs () {
      return this.$attrs
    },
  },
  watch: {
    menu (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.menu = val
    },
  },
  methods: {
  },
}
</script>