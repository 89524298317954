String.prototype.decodeBase64 = function () {
  const binaryImg = atob(this.valueOf())
  const { length } = binaryImg
  const arrayBuffer = new ArrayBuffer(length)
  const uintArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < length; i++) {
    uintArray[i] = binaryImg.charCodeAt(i)
  }
  return uintArray
}

String.prototype.formatMoney = function (decimalCount = 2, decimal = '.', thousands = ',') {
  decimalCount = Math.abs(decimalCount)
  decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount
  let amount = Number.isNaN(this.valueOf()) ? 0 : parseInt(this.valueOf(), 10)
  const negativeSign = amount < 0 ? '-' : ''
  const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString()
  const j = (i.length > 3) ? i.length % 3 : 0
  return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
}

String.prototype.chainObject = function (object) {
  const array = ['object', ...this.valueOf().split('.')]
  return eval(array.join('?.'))
}

String.prototype.first = function () {
  return this.valueOf().substr(0, 1)
}
String.prototype.camelToSnakeCase = function (separator = '_') {
  return this.valueOf().replace(/[A-Z]/g, letter => `${separator}${letter.toLowerCase()}`)
}

String.prototype.snakeToCamelCase = function () {
  return this.valueOf().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))
}
//  sv-SE yyyy-mm-dd
String.prototype.dateFormat = function (params = {}, locale = 'en-US') {
  const {
    year = 'numeric',
    month = '2-digit',
    day = '2-digit',
    hour = null,
    minute = null,
    second = null,
  } = params

  if (!this.valueOf()) return 'N/A'

  const date = new Date(this.valueOf())
  const options = { year, day, month, hour, minute, second }
  Object.keys(options).forEach(key => {
    if (!options[key]) delete options[key]
  })
  return date.toLocaleString(locale, options)
}

String.prototype.dateFormat_ENUS = function () {
  const date = new Date(this.valueOf())
  return date.toLocaleString('en-US', {
    day: 'numeric', // numeric, 2-digit
    year: 'numeric', // numeric, 2-digit
    month: 'long', // numeric, 2-digit, long, short, narrow
    hour: 'numeric', // numeric, 2-digit
    minute: 'numeric', // numeric, 2-digit
  })
}

String.prototype.addDays = function (days) {
  const date = this.valueOf() || null
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i
  if (!date || !regex.test(date)) return
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result.toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

String.prototype.subDays = function (days) {
  const date = this.valueOf() || null
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i
  if (!date || !regex.test(date)) return
  const result = new Date(date)
  result.setDate(result.getDate() - days)
  return result.toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

String.prototype.extractFirstInt = function () {
  return parseInt(this.valueOf().match(/[0-9]+/i)[0], 10)
}

String.prototype.extractFirstString = function () {
  return this.valueOf().match(/[a-zA-Z]+/i)[0]
}

String.prototype.toInt = function () {
  return parseInt(this.valueOf(), 10)
}
