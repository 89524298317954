import { restrictedAccess } from '../middleware'

const childModules = require.context('.', false, /incubators(?!\.route)\.[a-z-]+(\.js$)/)
const routes = childModules.keys().map(name => childModules(name).default || childModules(name))
export default {
  path: 'incubators',
  component: () => import('@/views/admin/incubators/Index'),
  children: [
    {
      path: '',
      name: 'incubators.manage',
      component: () => import('@/views/admin/incubators/manage/Index'),
      beforeEnter: restrictedAccess(1),
    },
    ...routes,
  ],
}