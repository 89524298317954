export default {
  path: 'feeding-programs',
  component: () => import('@/views/admin/stocks/feeding-programs/Index'),
  children: [
    {
      path: 'create',
      name: 'stocks.feeding-programs.create',
      component: () => import('@/views/admin/stocks/feeding-programs/manage/Create'),
    },
    {
      path: '',
      name: 'stocks.feeding-programs',
      component: () => import('@/views/admin/stocks/feeding-programs/manage/Index'),
    },
    {
      path: 'categories',
      name: 'stocks.feeding-programs.categories',
      component: () => import('@/views/admin/stocks/feeding-programs/categories/Index'),
    },
    {
      path: ':feedingProgramId/show',
      name: 'stocks.feeding-programs.show',
      component: () => import('@/views/admin/stocks/feeding-programs/manage/Show'),
    },
  ],
}