import jwtDecode from 'jwt-decode'
import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'

/** @type {VueWithCryptoJS} */
const vue = Vue

vue.use(VueCryptojs)

const decryptData = data => vue.CryptoJS.AES.decrypt(data, process.env.VUE_APP_COOKIE_SECRET).toString(vue.CryptoJS.enc.Utf8)
const encryptData = data => vue.CryptoJS.AES.encrypt(data, process.env.VUE_APP_COOKIE_SECRET).toString()

const storeToLocalStorage = (name, data) => {
  localStorage[name] = encryptData(data)
}

const getFromLocalStorage = name => {
  const item = localStorage[name]
  if (!item) return null
  return decryptData(item)
}

const state = {
  token: getFromLocalStorage('token'),
  // exp: null,
  user: getFromLocalStorage('userName'),
  accessLevel: Number(getFromLocalStorage('accessLevel')),
  mainPage: getFromLocalStorage('mainPage'),
}

const mutations = {
  // SET_TOKEN (state, payload) {
  //   state.token = payload
  // state.exp = decodeExpiry(payload)
  // },
  SET_MAIN_PAGE(state, payload) {
    state.mainPage = payload
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_ACCESS_LEVEL(state, payload) {
    state.accessLevel = payload
  },
  CLEAR_TOKEN(state) {
    state.token = null
    state.exp = null
    state.accessLevel = null
    state.user = null
  },
}

const actions = {
  setToken({ commit }, payload) {
    storeToLocalStorage('token', payload)
    commit('SET_TOKEN', payload)
  },
  setUser({ commit }, payload) {
    storeToLocalStorage('userName', payload)
    commit('SET_USER', payload)
  },
  setAccessLevel({ commit }, payload) {
    storeToLocalStorage('accessLevel', payload.toString())

    commit('SET_ACCESS_LEVEL', payload)
  },
  setMainPage({ commit }, payload) {
    storeToLocalStorage('mainPage', payload)
    commit('SET_MAIN_PAGE', payload)
  },
  clearToken({ commit }) {
    commit('CLEAR_TOKEN')
  },
  logout() {
    localStorage.userName = null
    localStorage.accessLevel = null
    localStorage.token = null
    localStorage.mainPage = null
  },
}

const getters = {
  exp({ token }) {
    if (!token) return null
    const { exp } = jwtDecode(token)
    return new Date(exp * 1000)
  },
}

const authStoreModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: true,
}

export default authStoreModule
/**
 * @typedef {typeof Vue & {CryptoJS?: any}} VueWithCryptoJS
 */