export default {
  path: 'admin-controls',
  component: () => import('@/views/admin/admin-controls/Index'),
  children: [
    {
      path: '',
      name: 'herica-clients',
      component: () => import('@/views/admin/admin-controls/herica-clients/Index'),
    },
  ],
}