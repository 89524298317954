import Echo from 'laravel-echo'
import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import Pusher from 'pusher-js'
// import md5 from 'md5'
/** @type {VueWithCryptoJS} */
const vue = Vue
vue.use(VueCryptojs)

/** @type {CustomWindow} */
const customWindow = window
customWindow.Pusher = Pusher

const decryptData = data => vue.CryptoJS.AES.decrypt(data, process.env.VUE_APP_COOKIE_SECRET).toString(vue.CryptoJS.enc.Utf8)

const getFromLocalStorage = name => {
  const item = localStorage[name]
  if (!item) return null
  return decryptData(item)
}

const development = () => ({
  authEndpoint: `${process.env.VUE_APP_SERVER_DOMAIN}/api/broadcasting/auth`,
  broadcaster: 'pusher',
  encrypted: false,
  cluster: 'mt1',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
  forceTLS: false,
  disableStats: true,
  wsPort: 6001,
})

const production = () => ({
  authEndpoint: `${process.env.VUE_APP_SERVER_DOMAIN}/api/broadcasting/auth`,
  broadcaster: 'pusher',
  encrypted: true,
  cluster: 'mt1',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
  forceTLS: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  scheme: 'https',
})
const echoSettings = process.env.NODE_ENV === 'development' ? development() : production()

const echo = new Echo(echoSettings)
echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${getFromLocalStorage('token')}`

const state = {
  echo,
}

const actions = {
  // setToken ({ state }, payload) {
  //   if (!payload) return
  //   state.
  // }

}

const websocketStoreModule = {
  namespaced: true,
  strict: true,
  state,
  // actions,
}

export default websocketStoreModule

/** 
 * @typedef {globalThis.Window & {Pusher?: typeof Pusher}} CustomWindow
 * @typedef {typeof Vue & {CryptoJS?: any}} VueWithCryptoJS
 * */