import { restrictedAccess } from '../middleware'

export default {
  path: '/reports',
  component: () => import('@/views/admin/reports/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'reports.sales',
      component: () => import('@/views/admin/reports/SaleReports'),
    },
  ],
}
