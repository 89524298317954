import Vue from 'vue'
import Vuex from 'vuex'
import websocketStoreModule from './websocket.store'
import authStoreModule from './auth.store'


const modules = {
  websocket: websocketStoreModule,
  auth: authStoreModule,
}

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .6), rgba(25, 118, 210, .6)',
  },
  mutations: {
  },
  actions: {
  },
  modules,
})

export default store

/**
 * Description
 * @returns {import('vuex').Store<any>}
 */
export const useStore = () =>
  store
