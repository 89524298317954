import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const options = {
  customProperties: true,
}

const themes = {
}

export default new Vuetify({
  icons: {
    iconFont: 'mdi',
  },
  theme: {
    options,
    light: true,
    themes,
  },
})
