function parseDate (date) {
  if (!date) return new Date()
  return new Date(date)
}

Window.objectChangeKeyCase = function (data = {}, caseFormat = 'snakeToCamelCase', array = false) {
  if (!['snakeToCamelCase', 'camelToSnakeCase'].includes(caseFormat)) return {}
  return Object.keys(data)
    .reduce((result, key) => {
      const key2 = key.toString()[caseFormat]()
      if (data[key] && Array.isArray(data[key]) && array) {
        result[key2] = data[key].map(item => Window.objectChangeKeyCase(item, caseFormat))
        return result
      }
      if (data[key] && typeof data[key] === 'object' && !Array.isArray(data[key])) {
        result[key2] = Window.objectChangeKeyCase(data[key], caseFormat)
        return result
      }
      result[key2] = data[key]
      return result
    }, {})
}

Window.objectFilterKeys = function (object = {}, keys = []) {
  return Object.keys(object)
    .filter(key => !keys.includes(key))
    .reduce((result, key) => {
      result[key] = object[key]
      return result
    }, {})
}

Window.getCurrentDate = function (date = new Date(), locale = 'sv-SE') {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

Window.watchInputVars = function (defaults, closure, excluded = []) {
  return Object.keys(defaults)
    .filter(key => !excluded.includes(key))
    .reduce((result, key) => {
      result[key] = closure
      return result
    }, {})
}

Window.getDateMysql = function (date = null, format = 'sv-SE') {
  return parseDate(date).toLocaleString(format, { year: 'numeric', month: '2-digit', day: '2-digit' })
}

Window.computeDurationBetweenDates = function (dateA, dateB) {
  if (!dateA || !dateB) return 0
  return Math.ceil((new Date(dateA) - new Date(dateB)) / (1000 * 60 * 60 * 24))
}

Window.objectEmpty = function (object) {
  if (typeof object !== 'object' || Array.isArray(object)) return false
  return !Object.keys(object).length
}

Window.subtractDates = function (dateA, dateB) {
  dateA = new Date(dateA).setHours(0, 0, 0)
  dateB = new Date(dateB).setHours(0, 0, 0)

  return (dateA - dateB) / (1000 * 60 * 60 * 24)
}

Window.objectCompare = function (objectA, objectB) {
  return Object.keys(objectA).every(key => objectA[key] === (objectB[key] || null))
}

Window.intToInvoiceNumber = function (invoice = 0) {
  if (!invoice) return null
  return `DR${invoice.toString().padStart(6, '0')}`
}

Window.parseNumericAttributes = function (object) {
  return Object.keys(object)
    .reduce((result, key) => {
      const value = Number(object[key])
      result[key] = isNaN(value) ? object[key] : value
      return result
    }, {})
}

Window.removeNullAttrs = function (object) {
  return Object.keys(object)
    .reduce((result, key) => {
      if (Array.isArray(object[key]) && !object[key].length) return result
      result[key] = object[key]
      return result
    }, {})
}

Window.date = function (date) {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

Window.downloadExcel = function (data, name) {
  const blob = new Blob([data], { type: 'application/xlsx' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = `${name}_${new Date().getTime()}.xlsx`
  link.click()
  URL.revokeObjectURL(link.href)
}

Window.openPdf = function (base64Data) {
  const blob = new Blob([base64Data.decodeBase64()], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.target = '__blank'
  link.click()
  URL.revokeObjectURL(link.href)
}