import Vue from 'vue'

const requireModule = require.context('.', false, /\.vue$/)

requireModule.keys().forEach(key => {
  const moduleName = key
    .replace(/(\.\/|\.vue)/g, '')
    .replace(/^[A-Z]/g, letter => letter.toLowerCase())
    .camelToSnakeCase('-')
  Vue.component(moduleName, () => import('' + key))
})