import jwtDecode from 'jwt-decode'

const convertUTCSecToDate = exp => new Date(new Date(0).setUTCSeconds(exp))

const getCurrentDateNoTime = () => new Date(new Date().setHours(0, 0, 0))

const mixin = {
  created () {
    this.initializeWebsockets()
  },
  methods: {
    initializeWebsockets () {
      const { echo } = this.$store.state.websocket
      const { token } = this.$store.state.auth
      const { sub } = jwtDecode(token)
      echo.channel(`logout.${sub}`)
        .listen('LogoutEvent', this.logoutEvent)
    },
    logoutEvent ({ exp }) {
      const expiryDate = convertUTCSecToDate(exp)
      const currentDate = getCurrentDateNoTime()
      if (expiryDate > currentDate) return
      this.$cookies.remove('token')
      window.location.reload()
    },
  },
}

export default mixin