import { restrictedAccess } from '../middleware'

const childModules = require.context('.', false, /orders(?!\.route)\.[a-z-]+(\.js$)/)
const routes = childModules.keys().map(name => childModules(name).default || childModules(name))
export default {
  path: '/orders',
  component: () => import('@/views/admin/orders/default/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      component: () => import('@/views/admin/orders/default/manage/Index'),
      children: [
        {
          path: '',
          name: 'orders.table',
          component: () => import('@/views/admin/orders/default/manage/Table'),
        },
        {
          path: ':orderId/edit',
          name: 'orders.edit',
          component: () => import('@/views/admin/orders/default/manage/Edit'),
        },
        {
          path: ':orderId/show',
          name: 'orders.show',
          component: () => import('@/views/admin/orders/default/manage/Show'),
        },
        {
          path: 'create',
          name: 'orders.create',
          component: () => import('@/views/admin/orders/default/create/Index'),
        },
      ],
    },
    ...routes,
  ],
}