import { restrictedAccess } from '../middleware'

export default {
  path: 'customer-forecasts',
  component: () => import('@/views/admin/customer-forecasts/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'customer-forecasts.table',
      component: () => import('@/views/admin/customer-forecasts/Table'),
    },
    {
      path: 'purchase-request/create',
      name: 'customer-forecasts.purchase-request.create',
      component: () => import('@/views/admin/customer-forecasts/purchase-requests/Create'),
    },
  ],
}
