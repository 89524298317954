import { restrictedAccess } from '../middleware'

const childModules = require.context('.', false, /stocks(?!\.route)\.[a-z-]+(\.js$)/)
const routes = childModules.keys().map(name => childModules(name).default || childModules(name))
export default {
  path: '/stocks',
  component: () => import('@/views/admin/stocks/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'stocks.manage',
      component: () => import('@/views/admin/stocks/Page'),
    },
    ...routes,
  ],
}