<template lang="pug">
v-tabs(
  v-bind="$attrs"
)
  v-tab(
    v-for="(item, index) in items"
    :key="index"
    dark
    link
    exact
    :to="item.path"
  )
    slot(
      name="tab.item"
      v-bind:item="item"
    )
      span {{ item.label }}
</template>
<script>
export default {
  name: 'BaseTabs.vue',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
