Number.prototype.phpFormat = function () {
  const number = this.valueOf()
  return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'Php' }).format(number)
}

Number.prototype.currencyFormat = function () {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.valueOf())
}

Number.prototype.unitFormat = function (unit = 'kg') {
  const number = this.valueOf()
  return new Intl.NumberFormat('en-US', { style: 'unit', unit }).format(number)
}

Number.prototype.moneyFormat = function () {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.valueOf())
}

Number.prototype.celsiusFormat = function () {
  return new Intl.NumberFormat('en', { style: 'unit', unit: 'celsius', maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(this.valueOf())
}