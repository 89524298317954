export default {
  path: '/preventive-maintenance',
  name: 'preventive-maintenance.layout',
  component: () => import('@/views/admin/preventive-maintenance/Layout.vue'),
  children: [
    {
      path: '',
      name: 'preventive-maintenance.list',
      component: () => import('@/views/admin/preventive-maintenance/list/Template.vue'),
    },
    {
      path: 'templates',
      name: 'preventive-maintenance.template',
      component: () => import('@/views/admin/preventive-maintenance/templates/Template.vue'),
    },
  ],
}
