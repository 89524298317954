<template>
  <v-tooltip
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'TooltipBtn',
  props: {
    tooltip: {
      type: String,
      default: 'Tooltip',
    },
  },
  // btnClass: { type: String, default: '' },
  // rounded: { type: Boolean, default: false },
  // small: { type: Boolean, default: false },
  // tooltip: { type: String, default: 'ToolTip' },
  // fab: { type: Boolean, default: false },
  // outlined: { type: Boolean, default: false },
  // color: { type: String, default: 'primary' },
  // dark: { type: Boolean, default: true },
}
</script>
