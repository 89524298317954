<template lang="pug">
  v-snackbar(
      outlined
      absolute
      top
      dismissible
      color="error"
      v-model="show"
    )
      template(v-slot:action="{ attrs }")
        v-btn(
          color="red"
          text
          v-bind="attrs"
          @click="clearErrors"
        )
          span Close
      div.text-uppercase(v-if="show")
        v-icon.error--text.mr-2 mdi-alert
        span {{ show.first() }}
</template>
<script>
export default {
  name: 'BaseSnackbar',
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    show: null,
  }),
  watch: {
    errors (val) {
      this.show = val.errors || val.error
    },
  },
  methods: {
    clearErrors () {
      this.$emit('update:errors', {})
      this.$emit('clear-errors')
    },
  },
}
</script>