<template lang="pug">
  v-combobox.ma-0.pa-0(
    label="search"
    multiple
    outlined
    v-model="vals"
    v-bind="$attrs"
  )
    template(v-slot:selection="{ item, index, selected }")
      v-chip(
        color="blue-grey darken-3"
        dark
        :input-value="selected"
        label
        small
      )
        span {{ item }}
        v-icon(small @click="deleteItem(index)") $delete
</template>
<script>
export default {
  name: 'BaseCombobox',
  props: ['value'],
  computed: {
    vals: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    deleteItem (index) {
      this.vals.splice(index, 1)
    },
  },
}
</script>