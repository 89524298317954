import { restrictedAccess } from '../middleware'

export default {
  path: 'employees',
  component: () => import('@/views/admin/employees/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '/',
      name: 'employees.table',
      component: () => import('@/views/admin/employees/Table'),
    },
    {
      path: 'create',
      name: 'employees.create',
      component: () => import('@/views/admin/employees/Create'),
    },
    {
      path: ':employee/edit',
      name: 'employees.edit',
      component: () => import('@/views/admin/employees/Edit'),
    },
  ],
}