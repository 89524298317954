import { restrictedAccess } from '../middleware'

export default {
  path: 'purchase-requests',
  component: () => import('@/views/admin/purchase-requests/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'purchase-requests.table',
      component: () => import('@/views/admin/purchase-requests/Table'),
    },
    {
      path: 'create',
      name: 'purchase-requests.create',
      component: () => import('@/views/admin/purchase-requests/Create'),
    },
  ],
}
