import { restrictedAccess } from '../middleware'

export default {
  path: 'billings',
  component: () => import('@/views/admin/orders/default/billings/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      name: 'orders.billings.table',
      component: () => import('@/views/admin/orders/default/billings/Table'),
    },
    {
      path: 'create',
      name: 'orders.billings.create',
      component: () => import('@/views/admin/orders/default/billings/Create'),
    },
    {
      path: ':billingId/show',
      name: 'orders.billings.show',
      component: () => import('@/views/admin/orders/default/billings/Show'),
    },
  ],
}