import Vue from 'vue'

const removeCategory = (id, category) => id.replace(category, '')

Vue.prototype.$objectFilterKeys = function (object = {}, keys = []) {
  return Object.keys(object)
    .filter(key => !keys.includes(key))
    .reduce((result, key) => {
      result[key] = object[key]
      return result
    }, {})
}
Vue.prototype.$objectAttributesToNumeric = function (object = {}, attributes = []) {
  return Object.keys(object).reduce((result, key) => {
    if (attributes.includes[key] || isNaN(object[key])) {
      result[key] = object[key]
      return result
    }
    result[key] = Number(object[key])
    return result
  }, {})
}

Vue.prototype.$intToInvoiceNumber = function (invoice = 0) {
  if (!invoice) return null
  return `DR${invoice.toString().padStart(6, '0')}`
}

Vue.prototype.$getCurrentDate = function () {
  const date = new Date()
  const attributes = [
    date.getFullYear().toString(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ]
  return attributes.join('-')
}

Vue.prototype.$getCurrentDate_enUS = function () {
  const date = new Date()
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

Vue.prototype.$float = function (val = '0') {
  return parseFloat(val)
}

Vue.prototype.$genKeyIdCategory = function (id = '0', category = '', stringLength = 4) {
  if (!category) return
  const ID_NUM = (removeCategory(id, category).extractFirstInt() + 1).toString().padStart(stringLength, '0')
  return `${category}${ID_NUM}`
}

Vue.prototype.$objectEmpty = function (object) {
  if (typeof object !== 'object' || Array.isArray(object)) return true
  return !Object.keys(object).length
}

Vue.prototype.$objectChangeKeyCase = function (data = {}, caseFormat = 'snakeToCamelCase') {
  const result = {}
  if (!['snakeToCamelCase', 'camelToSnakeCase'].includes(caseFormat)) return {}
  Object.keys(data).forEach(key => {
    const key2 = key.toString()[caseFormat]()
    result[key2] = data[key]
  })
  return result
}

Vue.prototype.$autoAbbriviate = function (string) {
  if (!string) return
  const regex = /(?:\()([A-Za-z0-9]+)(?:\))/g
  const [index, result] = regex.exec(string) || []
  return !result ? string : result
}

Vue.prototype.$populateInputData = function (args, callback = null) {
  const { data = null, input = {} } = args || {}
  const keys = ob => Object.keys(ob)
  const forEachFunc = key => {
    const snakeKey = key.camelToSnakeCase()
    if (!data) {
      this[key] = this.defaultData[snakeKey]
      return
    }
    this[key] = data[snakeKey]
  }
  keys(input)
    .forEach(callback || forEachFunc)
}

Vue.prototype.$getInputData = function (...args) {
  const [defaults = {}, callback = null] = args || []
  const keys = ob => Object.keys(ob)
  const forEachFunc = (result, key) => {
    const snakeKey = key.camelToSnakeCase()
    if (this[key] == null) return result
    if (typeof this[key] === 'object' || Array.isArray(this[key])) {
      result[snakeKey] = Window.objectChangeKeyCase(this[key], 'camelToSnakeCase', true)
      return result
    }
    result[snakeKey] = this[key]
    return result
  }
  return keys(defaults)
    .reduce(callback || forEachFunc, {})
}

Vue.prototype.$resetInputData = function (defaults, callback) {
  const keys = ob => Object.keys(ob)
  const forEachFunc = key => {
    this[key] = defaults[key]
  }
  keys(defaults).forEach(callback || forEachFunc)
}

Vue.prototype.$swalSuccess = function (text) {
  this.$swal('success', {
    config: {
      title: {
        text,
      },
    },
  })
}

Vue.prototype.$swalConfirm = function (text) {
  return this.$swal('confirm', {
    hideOverlay: true,
    config: {
      iconVisible: false,
      title: {
        text,
        style: {
          style: {
            fontSize: '18px',
            color: 'grey',
          },
        },
      },
      buttonCancel: {
        text: 'Decline',
        style: {
          text: false,
          color: 'grey',
          class: 'white--text',
          depressed: true,
          width: '40%',
          maxWidth: '400px',
        },
      },
      buttonOk: {
        text: 'Proceed',
        style: {
          text: false,
          color: 'green',
          class: 'white--text',
          depressed: true,
          width: '30%',
          maxWidth: '400px',
        },
      },
    },
  })
}

Vue.prototype.$swalLoading = function (text) {
  this.$swal('loading', {
    config: {
      title: {
        text,
      },
    },
  })
}

Vue.prototype.$swalError = function (text) {
  this.$swal('error', {
    config: {
      title: {
        text,
      },
    },
  })
}
