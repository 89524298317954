<template lang="pug">
  v-tooltip(v-bind="$attrs")
    template(v-slot:activator="{ on }")
      v-btn(
        v-if="!noBtn"
        v-bind="$attrs"
        v-on="on"
        @click="$emit('click')"
      )
        slot
      span(v-else)
        slot(
          v-if="!iconLoading" 
          :on="on" 
          :loading="loading" 
          :isLoading="iconLoading"
        )
        slot(
          v-else 
          :on="on" 
          :loading="loading" 
          :isLoading="iconLoading" 
          name="icon-loader"
        )
          v-progress-circular(
            indeterminate
            :size="20"
            :width="2"
            :color="$attrs.color"
          )
    span {{ tooltip }}
</template>
<script>
export default {
  name: 'TooltipBtn',
  props: {
    tooltip: {
      type: String,
      default: 'Tooltip',
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      iconLoading: false,
    }
  },
  methods: {
    loading (loading) {
      this.iconLoading = loading
    },
  },
  // btnClass: { type: String, default: '' },
  // rounded: { type: Boolean, default: false },
  // small: { type: Boolean, default: false },
  // tooltip: { type: String, default: 'ToolTip' },
  // fab: { type: Boolean, default: false },
  // outlined: { type: Boolean, default: false },
  // color: { type: String, default: 'primary' },
  // dark: { type: Boolean, default: true },
}
</script>
