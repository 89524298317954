import { restrictedAccess } from '../middleware'

export default {
  path: '/vouchers',
  component: () => import('@/views/admin/vouchers/Index'),
  beforeEnter: restrictedAccess(5),
  children: [
    {
      path: '',
      component: () => import('@/views/admin/vouchers/manage/Index'),
      children: [
        {
          path: '',
          name: 'vouchers',
          component: () => import('@/views/admin/vouchers/manage/Table'),
        },
        {
          path: 'create',
          name: 'vouchers.create',
          component: () => import('@/views/admin/vouchers/manage/Create'),
        },
        {
          path: ':voucherId/edit',
          name: 'vouchers.edit',
          component: () => import('@/views/admin/vouchers/manage/edit/Index'),
        },
        {
          path: 'petty-cash',
          name: 'vouchers.petty-cash.all',
          component: () => import('@/views/admin/vouchers/petty-cash-vouchers/Index'),
        },
        {
          path: ':voucherId/petty-cash',
          name: 'vouchers.petty-cash.manage',
          component: () => import('@/views/admin/vouchers/petty-cash-vouchers/Index'),
        },
        {
          path: ':voucherId/petty-cash/create',
          name: 'vouchers.petty-cash.create',
          component: () => import('@/views/admin/vouchers/petty-cash-vouchers/Create'),
        },
        {
          path: ':voucherId/petty-cash/:ptCashVoucherId',
          name: 'vouchers.petty-cash.edit',
          component: () => import('@/views/admin/vouchers/petty-cash-vouchers/Edit'),
        },
        // {
        //   path: ':voucherId/petty-cash/:ptCashVoucherId/edit',
        //   name: 'vouchers.petty-cash.edit',
        //   component: () => import('@/views/admin/vouchers/manage/petty-cash-vouchers/Edit'),
        // },
      ],
    },
    {
      path: 'account-codes',
      name: 'account-codes',
      component: () => import('@/views/admin/vouchers/account-codes/Index'),
    },
    {
      path: 'cost-centers',
      name: 'cost-centers',
      component: () => import('@/views/admin/vouchers/cost-centers/Index'),
    },
    {
      path: 'revenue-sources',
      name: 'revenue-sources',
      component: () => import('@/views/admin/vouchers/revenue-sources/Index'),
    },
  ],
}
