export default {
  path: 'feed-details',
  // name: 'stocks.feed-details',
  component: () => import('@/views/admin/stocks/feed-details/Index'),
  children: [
    {
      path: '',
      name: 'stocks.feed-details.create',
      component: () => import('@/views/admin/stocks/feed-details/Create'),
    },
    {
      path: ':stock/edit',
      name: 'stocks.feed-details.edit',
      component: () => import('@/views/admin/stocks/feed-details/Edit'),
    },
  ],
}